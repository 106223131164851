import { NAMESPACE_TYPE } from "@/utils/types.enums";

import { Attachments } from "./types/queryApi.type";

export interface UserCohortData {
  cohort: Cohort;
  cohortUserData: CohortUserData;
}

export type CohortTags =
  | "new-lms2"
  | "new-lms3"
  | "enable_mentor_dashboard"
  | "mock-interview"
  | "show-plot"
  | "lock-project"
  | "live_session_v2"
  | "leaderboard-v2"
  | "ai-enabled"
  | "new-lms4"
  | "enable_nudges"
  | "enable_placement_nudges"
  | "civil-lms4";

export interface Cohort {
  pricingInfo: PricingInfo;
  filter: Filter;
  onBoarding: OnBoarding;
  tags: CohortTags[];
  productType: string;
  courseType: string;
  productId: string;
  courseId: string;
  status: string;
  title: string;
  description: string;
  cohortTitle: string;
  courseTitle: string;
  timelines: Timeline[];
  imageMedia: Attachments[];
  metaAssets: [];
  importantLinks: ImportantLink[];
  electiveDocuments: ElectiveDocument[];
  mentors: [];
  __v: number;
  specialisationStudioDocuments: [];
  batchNumber: string;
  cohortType: string;
}

export interface InternalCohortType extends Cohort {
  isNewLms?: boolean | null;
  isCivilLms4Cohort?: boolean | null;
}

export interface PricingInfo {
  filters: Filter;
}

export interface Filter {
  countries: string[];
}

export interface OnBoarding {
  toDoList: string[];
}

export interface Timeline {
  key: string;
  value: string;
  type: string;
  meta: Meta;
}

export interface Meta {
  time: string;
}

export interface ImportantLink {
  key: string;
  value: string;
  type: string;
  meta: Meta2;
}

export interface Meta2 {
  link: string;
}

export interface ElectiveDocument {
  key: string;
  value: string;
  type: string;
  meta: Meta3;
}

export interface Meta3 {
  link: string;
}

export interface CohortUserData {
  onBoardingState: OnBoardingState;
  entryId: string;
  userId: number;
  state: string;
  cohortId: string;
  angelMentors: string[];
  source: string;
  sourceMeta: SourceMeta;
  capstoneOnboardingStatus: string;
  reviews: string[];
  electiveChoices: string[];
  specialisationChoices: string[];
  projectChoices: ProjectChoice[];
  __v: number;
  lmsStates?: LmsStates;
  meta?: Meta4;
  showCoachmarks?: boolean;
  queriesOnboardingDetails: {
    onboardingDone: boolean;
    coachMarksViewed: boolean;
  };
}

export interface UserCohortInfo {
  cohort: Cohort;
  cohortUserData: CohortUserData;
}

export type CohortUserDataInfo = UserCohortInfo[];

export interface OnBoardingState {
  toDoList: string[];
}

export interface SourceMeta {
  internalType: string;
  comments: string[];
}

export interface ProjectChoice {
  parentNodeId: string;
  childNodeId: string;
  nodeType: string;
}

export interface LmsStates {
  currentUnitId: string;
  currentModuleUnitId: string;
}

export interface Meta4 {
  NOTIFICATIONS_ONBOARD_POPUP: boolean;
}

export type UserCohortResponse = (UserCohortData & { filterId?: string })[];

export interface UserCohortRequestParams {
  source: "learner-hub" | "placements";
}

export interface ApiResponseError {
  data: ApiErrorResponse;
  status: number;
}

export interface ApiErrorResponse {
  message: string;
  messageCode: "INVALID_TOKEN" | string;
}
// Cohort Node Types

export interface CoreNodeMeta {
  isLocked?: boolean;
  isOngoing?: boolean;
}
export interface RootMeta extends CoreNodeMeta {
  title: string;
  cohort: string;
  startDate: string;
  endDate: string;
}

export interface StudioMeta extends CoreNodeMeta {
  studioType: StudioTypes;
  studioTitle: string;
  studioName: string;
  startDate: string;
  endDate: string;
  softwares: any[];
  mentorsData: any[];
}

export interface ModuleMeta extends CoreNodeMeta {
  courseModuleType: CourseModuleTypes;
  moduleTitle: string;
  about: string;
  startDate: string;
  endDate: string;
  moduleNumber: string;
  mentorsData: any[];
  electiveVideo?: string;
}

export interface TopicMeta extends CoreNodeMeta {
  startDate: string;
  endDate: string;
  topicNumber: string;
  topicTitle: string;
  title?: string;
}

export interface QuizMeta extends CoreNodeMeta {
  formId: string;
  time: number;
  quizType: QuizTypeTypes;
  topicNumber: string;
  startDate: string;
  endDate: string;
  deadline: string;
  softwareUsed: string[];
  skillsAssociated: string[];
  conceptAssociated: string[];
  taxonomyLevel: string[];
  isCompulsory: boolean;
  tag: string;
  difficultyLevel: string;
  attemptCount: number;
  title: string;
}

export interface BreakMeta extends CoreNodeMeta {
  learnerTitle: string;
  learnerDescription: string;
  igTitle: string;
  igDescription: string;
  lmTitle: string;
  lmDescription: string;
  startDate: string;
  endDate: string;
}

export interface MileStoneMeta extends CoreNodeMeta {
  milestoneTitle: string;
  milestoneDescription: string;
  startDate: string;
  endDate: string;
}
export interface AcademicLevellingMeta extends CoreNodeMeta {
  academicLevellingTitle: string;
  academicLevellingDescription: string;
  startDate: string;
  endDate: string;
}

export interface CapstoneStageMeta extends CoreNodeMeta {
  startDate: string;
  endDate: string;
  stageName: string;
  stageTitle: string;
  stageDescription: string;
  stageSessions: string;
  stageCredits: string;
  stageTasks: string;
  stageNumber: string;
  submissionDeadline: string;
}

export interface CapstoneAssignmentMeta extends CoreNodeMeta {
  title: string;
  time: string;
  tag: string;
  connectedUnitId: string;
  startDate: string;
  endDate: string;
  nodeTags: string[];
  groupTag: CapstoneAssignmentGroup;
}

type CohortNodeMeta =
  | RootMeta
  | StudioMeta
  | ModuleMeta
  | BreakMeta
  | MileStoneMeta
  | AcademicLevellingMeta
  | TopicMeta
  | QuizMeta;

type CapstoneNodeMeta = CapstoneStageMeta | CapstoneAssignmentMeta;

export enum NodeType {
  ROOT = "ROOT",
  STUDIO = "STUDIO",
  COURSE_MODULE = "COURSE_MODULE",
  BREAK = "BREAK",
  MILESTONE = "MILESTONE",
  ACADEMIC_LEVELLING = "ACADEMIC_LEVELLING",
  CAREER_SESSION = "CAREER_SESSION",
  MASTERCLASSES = "MASTER_CLASSES",
  TOPIC = "TOPIC",
  META = "META",
  QUIZ = "QUIZ",
  LIVE_SESSION = "LIVE_SESSION",
  MASTER_CLASSES = "MASTER_CLASSES",
}

export enum CapstoneNodeType {
  ASSIGNMENT = "ASSIGNMENT",
  STAGE_REVIEW = "STAGE_REVIEW",
  STAGE_INTRO = "STAGE_INTRO",
}

export enum CapstoneAssignmentGroup {
  TASK = "TASK",
  BONUS = "BONUS",
  GUIDE = "GUIDE",
}

export enum NodeStatus {
  ACTIVE = "ACTIVE",
}

type StudioTypes = "COMPULSORY" | "OPTIONAL" | "CHOICE_BASED";

type CourseModuleTypes = "SPECIALISATION" | "COMPULSORY" | "ELECTIVE" | "CHOICE_BASED" | "OPTIONAL";

type QuizTypeTypes = "EXAM" | "PRACTICE" | "REGULAR";

export interface CohortNode {
  nodeId: string;
  parentNodeId: string;
  type: NodeType;
  parentNodeName: string;
  meta: {
    studioDeadline?: string;
    isCurrentMentor: boolean;
    milestoneTitle?: string;
    documentCategory?: string;
    fileTypes?: string[];
    title?: string;
  } & CohortNodeMeta;
  children?: CohortNode[];
  skills: Skill[];
  contentSummary?: ContentSummary[];
  weeks: number;
  days: number;
}

export interface Skill {
  skillId: string;
  score: number;
}

export interface ContentSummary {
  type: string;
  count: number;
}

export interface CapstoneNode {
  nodeId: string;
  parentNodeId: string;
  type: CapstoneNodeType;
  treeId: string;
  childrenNodeIds: string[];
  children: CapstoneNode[];
  meta: CapstoneNodeMeta;
  namespace: NAMESPACE_TYPE.CAPSTONE;
  status: NodeStatus;
}

export interface StageContentListRequestParams {
  cohortId: string;
  nodeId: string;
  isPreview?: boolean;
}
interface OptionSchema {
  _id: string;
  optionId: string;
  value: string;
  nextQuestionIds: string[];
  solutionText: string;
  meta: any;
}

interface QuestionSchema {
  _id: boolean;
  questionId: string;
  questionType: string;
  hasParent: boolean;
  question: string;
  score: number;
  options: OptionSchema[];
  solutionIds: string[];
  prevQuestionId: string;
  nextQuestionId: string;
  skippable: boolean;
  meta: any;
  pageNo: number;
  questions: QuestionSchema[];
}

interface SectionSchema {
  questions: QuestionSchema[];
  meta: any;
}

export interface FormDetailsSchema {
  formId: string;
  title: string;
  description: string;
  allowedProductIds: string[];
  questions: QuestionSchema[];
  sections: SectionSchema[];
  meta: any;
}

export enum CourseType {
  BIM_A = "BIM_A",
  BIM_C = "BIM_C",
  MCD = "MCD",
  BIM_A_US = "BIM_A_US",
  BIM_C_US = "BIM_C_US",
  MCD_US = "MCD_US",
}
export interface NextCohortDateApiResponse {
  BIM_A: { date: string };
  BIM_C: { date: string };
  MCD: { date: string };
  BIM_A_US: { date: string };
  BIM_A_ME: { date: string };
  BIM_C_US: { date: string };
  MCD_US: { date: string };
  INT_DES: { date: string };
  INT_DES_US: { date: string };
}

export interface CohortDataApiRequest {
  cohortId: string;
}

export enum COHORT_TIMELINE_KEY {
  START_DATE = "START_DATE",
  CAPSTONE_START_DATE = "CAPSTONE_START_DATE",
  CAPSTONE_END_DATE = "CAPSTONE_END_DATE",
  EVALUATION_DATE = "EVALUATION_DATE",
  GRADUATION_DATE = "GRADUATION_DATE",
  PLACEMENT_FORM_DATE = "PLACEMENT_FORM_DATE",
  PLACEMENT_START_DATE = "PLACEMENT_START_DATE",
  KICK_OFF = "KICK_OFF",
  CAPSTONE_ONBOARDING_DATE = "CAPSTONE_ONBOARDING_DATE",
  COURSE_COMPLETION_DATE = "COURSE_COMPLETION_DATE",
}
